
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin, DataTableSortMixin, DebounceMixin, CoachRoutingMixin, BAIconsMixin } from "@/mixins";
import { formatDate } from "@/helpers/date";
import ScoutingReportsInfoProvider, { loadScoutingDataFn } from "@/components/hoc/ScoutingReportsInfoProvider.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { DataTableHeader, DataOptions } from 'vuetify';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';
import { title } from '@/pipes';

@Component({
	components: {
		Page,
		ScoutingReportsInfoProvider,
	},
})
export default class CoachScoutingSummary extends Mixins(
	DataTableSortMixin,
	DebounceMixin,
	MyCoachMixin,
	VuetifyMixin,
	CoachRoutingMixin, 
	BAIconsMixin
) {
	formatDate = formatDate;
	title = title;

  	athleteSearch: string = '';

	showConfirmDeleteReport: boolean = false;
	reportToDelete: ScoutingReportModel | null = null;
	confirmDeleteReport(report: ScoutingReportModel): void{
		this.reportToDelete = report;
		this.showConfirmDeleteReport = true;
	}

	headers: Array<DataTableHeader<any> & {color: string}> = [
		{ text: "Date", value: 'createdAt', sortable: true, color: 'baColorVibrantBlue--text' },
		{ text: "Location", value: 'gameDetails.location', sortable: true, color: 'baColorDeepBlue--text' },
		{ text: "No.", value: 'gameDetails.location', sortable: true, color: 'baColorDeepBlue--text' },
		{ text: "Player Name", value: 'PlayerName', sortable: true, color: 'baColorDeepBlue--text' },
		{ text: "Status", value: 'gameDetails.location', sortable: true, color: 'baColorDeepBlue--text' },
		{text: '',value: '',sortable: false, color: ''},
		{text: '',value: '',sortable: false, color: ''},
	];

	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 12,
		sortBy: ['createdAt'],
		sortDesc: [true],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	}

	tableUpdatePending: boolean = false;
	debounceUpdateTable(load: loadScoutingDataFn): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable(load);
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(load: loadScoutingDataFn): Promise<void>{
		return await this.loadScoutingData(load);
	}

	tableLoading: boolean = false;
	async loadScoutingData(load: loadScoutingDataFn): Promise<void>{
		this.tableLoading = true;
		try{
      const query: RepositoryQuery<ScoutingReportModel> = {
        search: this.athleteSearch,
        fields: ['playerDetails.firstName','playerDetails.lastName'],
      };
			const options: QueryOptions = {
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await load({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.tableLoading = false;
		}
	}
}
