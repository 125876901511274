
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { VuetifyMixin, DebounceMixin, StringsMixin } from '@/mixins';
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import { athleteApi } from '@/api/AthleteApi';
import AthletePreview from './AthletePreview.vue';

@Component({
	components:{
		AthletePreview,
	}
})
export default class AthleteAutocomplete extends Mixins(DebounceMixin, VuetifyMixin, StringsMixin){
	@Prop({ default: () => (["id", "name"]) }) searchFields: string[];
	@Prop({ default: 8 }) limitPerPage: number;
	@Prop({ default: undefined }) itemValue: string | undefined;
	@Prop({ type: String, default: "Keep typing to find an athlete..." }) noDataText: string;
	@Prop({ default: true, type: Boolean }) returnObject: boolean;
	@Prop({ default: false, type: Boolean }) clearOnSelect: boolean;
	@Prop({ default: null }) itemsFilter: (item: AthleteAutocompleteInfo) => boolean = null;
	@Prop({ required: true }) value: AthleteAutocompleteInfo;
	@Prop({ required: true }) resource: string;
	
	input(value: AthleteAutocompleteInfo): void{
		this.$emit('input', value);
	}

	loading: boolean = false;

	onChange(): void{
		if(this.clearOnSelect){
			this.$nextTick(() => {
				this.search = '';
				this.value = null;
			});
		}
	}

	search: string = "";
	items: AthleteAutocompleteInfo[] = [];
	get SearchItems(): AthleteAutocompleteInfo[]{
		if(this.itemsFilter === null) return this.items;
		return this.items.filter(this.itemsFilter);
	}

	@Watch('search') debounceSearch(): void{
		this.loading = true;
		this.debounceCallback('execSearch', this.execSearch.bind(this), 250);
	}
	
	async execSearch(): Promise<void>{
		if( this.IsEmpty(this.search) ) {
			this.loading = false;
			return;
		};

		this.loading = true;
		try{
			const res = await athleteApi.athleteAutocomplete(this.search, {
				limitPerPage: this.limitPerPage,
			});
			this.items = res.docs;
		}catch(e){
			console.error(`Failed to query ${this.resource}`, e);
		}finally{
			this.loading = false;
		}
	}

}
