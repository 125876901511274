
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class WinsTiesLosses extends Mixins(VuetifyMixin){

	@Prop({ default: 'baColorVibrantBlue' }) private color: string;
	@Prop() wins: number;
	@Prop() ties: number;
	@Prop() losses: number;

	get HeaderStyle(){
		return {
			'color': this.Color,
		}
	}

	get Color(){
		return this.getColor(this.color);
	}

}
