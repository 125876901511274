

import { DataTableHeader, DataPagination, DataOptions, DataSortFunction, DataTableFilterFunction } from 'vuetify';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DebounceMixin, DataTableSortMixin, VuetifyMixin, AppHostnameMixin, MyCoachMixin, MindsetDisplayMixin, AthleteRoutingMixin, StringsMixin, BAIconsMixin, AthleteEditMixin, CoachRoutingMixin, SportsSelectorMixin } from '@/mixins';
import { TeamModel, PlayerOnTeam } from '@/models/team';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import SortIcon from '@/components/ui/SortIcon.vue';
import { teamDashboardStore } from '@/store';
import { InvitePlayerFormValue } from '@/../types/interfaces';
import { ShareTeam, EditTeam, CoachGameReport } from '@/../types/constants/web_client_user.routes';
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import ContextMenu from '@/components/ui/ContextMenu.vue';
import { SportPosition } from '@best-athletes/ba-types';
import { teamApi } from '@/api/TeamApi';
import { userStore, notificationStore } from '@/store';

@Component({
	components: { 
		ConfirmationDialog, 
		SortIcon, 
		RecruitingProfileInfo,
		ContextMenu,
	},
})
export default class TeamRosterV2 extends Mixins(
		DebounceMixin, 
		VuetifyMixin, 
		StringsMixin, 
		BAIconsMixin,
		AppHostnameMixin, 
		DataTableSortMixin, 
		MyCoachMixin, 
		MindsetDisplayMixin, 
		AthleteRoutingMixin,
		CoachRoutingMixin,
		AthleteEditMixin,
		SportsSelectorMixin,
	){
	@Prop({ required: true }) team: TeamModel;
	@Prop({ default: 590 }) tableHeight: number;
	@Prop({ type: Boolean }) readOnly;
	@Prop({ type: Boolean }) coach;
	$refs: {
		players: any,
	};

	contextMenuItems = [
		{ icon: 'mdi-clipboard-edit', text: "Edit Roster", click: this.onEditRoster },
		{ icon: 'mdi-email-multiple', text: "Invite All", click: this.onInviteAll },
		{ icon: 'mdi-chart-timeline', text: "Game Report", click: this.onTeamGameReport},
		{ icon: 'mdi-share-variant', text: "Share Team", click: this.onShareRoster },
	];

	get MyAthleteId(): string {
		if( this.AthleteAppActive ) return userStore.athleteId;
		return undefined;
	}
	PlayerName(playerInfo): string {
		return playerInfo.firstName === ""? "athlete" : playerInfo.firstName;
	}

	async onEditRoster() {
		this.goToEditRoster('4');
	}
	async onShareRoster() {
		try{
			await teamApi.shareTeam(this.team.id);
		} catch(e) {
			console.log(e);
		}
		this.gotoShareRoster();
	}
	gotoShareRoster() {
		this.$router.push({
			name: ShareTeam,
			params:{
				teamId: this.team.id,
				owner: 'coach',
				sharingUrlId: 'share'
			}
		});
	}
	goToEditRoster(step?: string){
		this.$router.push({
			name: EditTeam,
			params:{
				teamId: this.team.id,
				currentStep: step,
			}
		});
	}
	async onTeamGameReport() {
		if( this.AthleteAppActive ) return;
		this.$router.push({
			name: CoachGameReport,
			params: {
				teamId: this.team.id,
			}
		});
	}
	invitationInProgress: boolean = false;
	invitedCount: number = 0;
	async onInviteAll() {
		this.invitationInProgress = true;
		this.invitedCount = 0;
		for( const player of this.team.players ) {
			if( this.IsEmpty( player.athleteId ) ) {
				const playerToInvite: InvitePlayerFormValue = {
					id: player.id,
					number: player.number,
					firstName: player.firstName,
					lastName: player.lastName,
					email: player.email,
					position: player.position,
				};
				await teamDashboardStore.invitePlayerByEmail({
					teamId: this.team.id,
					email: playerToInvite.email,
					player: playerToInvite,
				});
				this.invitedCount++;
			}
		}
		this.invitationInProgress = false;
		notificationStore.pushSnackbarSuccess({message:`Invited ${this.invitedCount} athletes`});
	}

	showRemovePlayerDialog: boolean = false;
	selectedPlayerId: string = "";
	confirmRemovePlayer(player: PlayerOnTeam): void{
		this.selectedPlayerId = player.id;
		this.showRemovePlayerDialog = true;
	}
	async removeSelectedPlayer(): Promise<void>{
		this.showRemovePlayerDialog = false;
		await teamDashboardStore.removePlayer({
			teamId: this.team.id,
			playerId: this.selectedPlayerId,
		});
	}

	get TableStyle():  Record<string,string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}
	get TableLoading(): boolean{
		return teamDashboardStore.patchPlayerLoading || this.progressDialog;
	}

	tableOptions: DataOptions = {
		page: 0,
		itemsPerPage: -1,
		sortBy: ['number'],
		sortDesc: [false],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	get HasNationalTeamPlayers(): boolean {
		for( const player of this.team.players ) {
			if( player.club !== null && player.caps != null ) return true;
		}

		return false;
	}

	search: string = "";
	pagination: DataPagination;
	get TableHeaders(): DataTableHeader<any>[]{
		var headers = [];
		headers.push({text: 'Number', value: 'number', width:100 });
		headers.push({text: 'Player',	value: 'lastName'});
		headers.push({text: 'Position',	value: 'position'});
		headers.push({text: 'Mindset', value: 'mindset', width:25, sortable: false })

		if( this.HasNationalTeamPlayers){
			headers.push({text: 'Club', value: 'club'});
			headers.push({text: 'Caps', value: 'caps'});
			headers.push({text: 'Goals', value: 'goals'});
		}
		else{
			headers.push({text: '', value: 'actions', sortable: false });
			if( !this.IsMobile ) {
				headers.push({text: '', value: 'data-table-expand', sortable: false});
			}
		}

		return headers;
	}

	expandedAthletes: any[] = [];
	toggleExpandRow(item: PlayerOnTeam, isExpanded: boolean, expand: (item, isExpanded) => void): void{
		if(this.readOnly) return;
		if(isExpanded){
			this.expandedAthletes = [];
		}else{
			expand(item, !isExpanded);
		}
	}
	playerFilter: DataTableFilterFunction = (value: any, search: string | null, item: PlayerOnTeam): boolean => {
		const query = new RegExp(search, 'ig');
		return query.test(item.firstName) ||
		query.test(item.lastName) ||
		query.test(item.position) ||
		query.test(item.number);
	}
	playerSort: DataSortFunction<PlayerOnTeam> = (items: PlayerOnTeam[], sortBy: string[], sortDesc: boolean[], locale: string) => {
		if(sortBy.length === 0) return items;
		const [field] = sortBy;
		const [desc] = sortDesc;
		switch(field){
		case 'number':
		case 'gradYear':
			return this.numericSort<PlayerOnTeam>(field, items, desc);
		default:
			return this.genericSort<PlayerOnTeam>(field, items, desc);
		}
	}

	get SearchLabel(): string{
		return `Search ${this.team.players.length} players`;
	}

	get UseAvatarPadding(): boolean{
		if(this.IsMobile) return false;

		return true;
	}

	get AvatarSize(): number{
		if(this.IsMobile) return 25;
		return 30;
	}

    updatingStatus = false;
	playersChanged: Array<PlayerOnTeam> = [];
	playerChanged(player: PlayerOnTeam) {
		const playerInfo: PlayerOnTeam = {...player};
		this.playersChanged.push(playerInfo);
	}
	get HasAnyPlayerChanged(): boolean {
		return this.playersChanged.length > 0;
	}
	async setPlayerChange(player: PlayerOnTeam, data: Partial<PlayerOnTeam>) {
		const playerChanged = this.playersChanged.find(p => p.id === player.id);
		if( this.IsEmpty(playerChanged) ) {
			const playerInfo: PlayerOnTeam = {...player, ...data};
			this.playerChanged(playerInfo);
		} else {
			for( const key of Object.keys(data) ) {
				playerChanged[key] = data[key];
			}
		}
	}
	async onNumberChange(player: PlayerOnTeam, number: string) {
		this.setPlayerChange(player, {number});
	}
	async onPositionChange(player: PlayerOnTeam, position: SportPosition) {
		this.setPlayerChange(player, {position});
	}
	async onPositionSecondaryChanged(player: PlayerOnTeam, secondaryPosition: SportPosition) {
		this.setPlayerChange(player, {secondaryPosition});
	}
    async onSetInjuryStatus(player: PlayerOnTeam) {
        this.updatingStatus = true;
        player.injured = !player.injured;
		this.setPlayerChange(player, {injured: player.injured});
        this.updatingStatus = false;
    }
    async onSetEligibilityStatus(player: PlayerOnTeam) {
        this.updatingStatus = true;
        player.eligibleToPlay = !player.eligibleToPlay;
		this.setPlayerChange(player, {eligibleToPlay: player.eligibleToPlay});
        this.updatingStatus = false;
    }

	patchPlayers() {
		for( const p of this.playersChanged ) {
			this.patchPlayer(p.id, this.team.id, p);
		}
		this.playersChanged = [];
	}
}
