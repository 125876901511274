
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class ChipGroup extends Mixins(VuetifyMixin){

	@Prop({ type: Boolean, default: false }) disabled;
	@Prop({ type: Boolean, default: false }) centered;
	@Prop({ type: Boolean, default: false }) buttons;
	@Prop({ type: Boolean, default: true }) small: boolean;
	@Prop({ type: String }) size;
	@Prop({ default: 'auto' }) height;
	@Prop( { type: Array, required: true }) items;
	@Prop( { type: [Array, String] }) value;
	@Prop({ default: 'secondary' }) activeColor: string;
	get ActiveColor(): string{
		return this.getColor(this.activeColor);
	}
	@Prop({ default: 'baColorPrimaryText' }) textColor: string;
	get TextColor(): string{
		return this.getColor(this.textColor);
	}
	@Prop({ default: 'baColorGray4' }) borderColor: string;
	get BorderColor(): string{
		return this.getColor(this.borderColor);
	}

	get MultipleSelect(): boolean{
		return this.$attrs.multiple !== undefined;
	}
	get chipGroupStyle(): Record<string, string>{
		return {
			'height': `${this.height}px`,
		};
	}
	get chipStyle(): Record<string, string>{
		if(this.buttons){
			return {
				'font-size': '12px',
				'color': this.TextColor,
				'border-color': this.BorderColor,
				'border-width': '1px',
				'padding': '0px',
				'border-radius': '24px',
			};
		}
		return {
			'font-size': '12px',
			'color': this.TextColor,
			'border-color': this.BorderColor,
			'border-width': '1px',
		};
	}

	onInput(data): void{
		this.$emit('input', data);
	}
	filterIsActive (filterName): boolean {
		if(this.MultipleSelect){
			return this.value.find(filter => filter === filterName) !== undefined;
		}else{
			return this.value === filterName;
		}
	}
	getEventChipColor (event): string | undefined {
		if(this.filterIsActive(event)){
			return this.ActiveColor;
		}
		return;
	}
}
