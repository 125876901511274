
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, MindsetMixin } from '@/mixins';
import MindsetInformation from '@/components/mindset/MindsetInformation.vue'
import RyzerAthleteTypeCard from '@/components/mindset/RyzerAthleteTypeCard.vue';

@Component({
	components: { 
		MindsetInformation,
		RyzerAthleteTypeCard,
	 },
})
export default class CoachMindsetReportCard extends Mixins(VuetifyMixin, MindsetMixin) {
	@Prop({ type: Boolean }) loading: boolean;
}
