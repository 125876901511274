
import { Component, Mixins } from "vue-property-decorator";
import Page from "./Page.vue";
import { config } from '@/config/config';

import { VuetifyMixin, MyCoachMixin, MyTeamsMixin, AppHostnameMixin } from "@/mixins";
import CoachProfileCard from "@/components/profile/CoachProfileCard.vue";
import TeamManagement from "@/components/teams/TeamManagement.vue";
import CoachMindsetReportCard from "@/components/profile/coach/CoachMindsetReportCard.vue"

@Component({
	components: {
		Page,
		CoachProfileCard,
		TeamManagement,
		CoachMindsetReportCard,
	}
})
export default class CoachDashboard extends Mixins(MyCoachMixin, MyTeamsMixin, VuetifyMixin, AppHostnameMixin) {
	reloadMyTeams = true;

	config = config;
}
