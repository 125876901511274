
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, TeamDashboardMixin, MyCoachMixin, StringsMixin, DebounceMixin, BAIconsMixin } from "@/mixins";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import TeamHeadingCard from '@/components/teams/TeamHeadingCard.vue';
import { ScoutingObservationModel } from "@/models";
import { scoutingObservationApi } from '@/api/ScoutingObservationApi'
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { ScoutingReportScore } from "types";

@Component({
	components: {
		Page,
		TeamHeadingCard,
	},
})
export default class TeamScout extends Mixins(VuetifyMixin, TeamDashboardMixin, MyCoachMixin, StringsMixin, ScoutingReportScoreSelectorMixin, DebounceMixin, BAIconsMixin) {

	get IsLoading(): boolean {
		return !this.loadedScoutingObservations || this.IsTeamLoading;
	}
	scoutingObservations: Array<ScoutingObservationModel> = [];
	mounted() {
		this.loadScoutingObservations();
	}

	loadedScoutingObservations: boolean = false;
	async loadScoutingObservations() {
		this.loadedScoutingObservations = false;
		await this.loadTeam();
		
		this.scoutingObservations = [];
		for( const athlete of this.TeamPlayers ) {
			const observations = await this.findObservationsByAthleteId(athlete.athleteId);
			if( this.IsNotEmpty(observations) ) {
				this.scoutingObservations = this.scoutingObservations.concat(observations);
			}
		}
		this.loadedScoutingObservations = true;
	}
	async findObservationsByAthleteId(athleteId: string): Promise<Array<ScoutingObservationModel>> {
		if( this.IsEmpty(athleteId) ) return [];

		const query: RepositoryQuery<ScoutingObservationModel> = {
			$match: {
				athleteId: athleteId,
				coachId: this.MyCoachId,
			}
		}
		const options: QueryOptions = {
			sort: {
				fields: [
					{ field: 'date', desc: true }
				]
			}
		};
		const response: PaginatedResponse<ScoutingObservationModel> = await scoutingObservationApi.queryAll(query, options);
		if( this.IsEmpty(response.count) ) return undefined;
		return response.docs;
	}
	GetObservation(athleteId: string): ScoutingObservationModel {
		return this.scoutingObservations.find(o => o.athleteId === athleteId);
	}
	HasObservation(athleteId: string): boolean {
		return this.IsNotEmpty(this.GetObservation(athleteId));
	}
	ObservationRating(athleteId: string): ScoutingReportScore {
		if( !this.HasObservation(athleteId) ) return undefined;
		const observation = this.GetObservation(athleteId);
		return observation.rating;
	}
	ObservationNotes(athleteId: string): string {
		if( !this.HasObservation(athleteId) ) return '';
		const observation = this.GetObservation(athleteId);
		return observation.notes;
	}

	get JerseySize(): number {
		if( this.IsMobile ) return 5;
		return 10;
	}
	get PhotoSize(): number {
		if( this.IsMobile ) return 25;
		return 50;
	}

	async createObservation(athleteId: string): Promise<ScoutingObservationModel> {
		return new ScoutingObservationModel( { coachId: this.MyCoachId, athleteId } )
	}
	async saveObservation(observation: ScoutingObservationModel): Promise<ScoutingObservationModel> {
		if( this.IsEmpty(observation) ) return;
		const isNewObs = this.IsEmpty( observation.id );
		const newObs = await scoutingObservationApi.save(observation);
		if( isNewObs ) {
			this.scoutingObservations.push(newObs);
		}
		return newObs;
	}
	async onSetRating(athleteId: string, rating: ScoutingReportScore) {
		var observation = this.GetObservation(athleteId);
		if( this.IsEmpty(observation) ) {
			observation = await this.createObservation(athleteId);
		}
		observation.rating = rating;
		this.onObservationChanged(observation);
	}
	async onChangeNotes(athleteId: string, notes: string ) {
		var observation = this.GetObservation(athleteId);
		if( this.IsEmpty(observation) ) {
			observation = await this.createObservation(athleteId);
		}
		observation.notes = notes;
		this.onObservationChanged(observation);
	}
	async onObservationChanged(observation: ScoutingObservationModel) {
		this.debounceCallback('scoutingObservation', () => { this.saveObservation(observation) }, 2500)
	}

	creatingObservation: boolean = false;
	viewingObservations: boolean = false;
	currentAthlete: string;
	athleteObservations: Array<ScoutingObservationModel>;
	async onCreateNewObservation(athleteId: string) {
		this.currentAthlete = athleteId;
		this.creatingObservation = true;
	}
	async onViewObservations(athleteId: string) {
		this.currentAthlete = athleteId;
		this.viewingObservations = true;
		this.athleteObservations = await this.findObservationsByAthleteId(athleteId);
	}

}
