
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin, PaginatedTableMixin, AthleteApiMixin, StringsMixin, BAIconsMixin } from "@/mixins";
import { DataTableHeader } from 'vuetify';
import { coachRecruitingSharingStore, notificationStore } from '@/store'
import { athleteApi } from "@/api/AthleteApi";
import { RepositoryQuery } from "@/../types/interfaces";
import { TargetedSharingModel, AthleteProfileModel } from '@/models'
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { CoachViewAthlete } from '@/../types/constants/web_client_user.routes';
import SharingResponseBtn from "@/components/sharing/SharingResponseBtn.vue";
import SharingResponseNote from "@/components/sharing/SharingResponseNote.vue";
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import { TargetedSharingData } from '@/models'

@Component({
	components: {
		Page,
		SharingResponseBtn,
		SharingResponseNote,
		AdminAthleteAutocomplete
	},
	
})
export default class CoachConnectWithAthletes extends Mixins( VuetifyMixin, MyCoachMixin, PaginatedTableMixin, AthleteApiMixin, StringsMixin, BAIconsMixin ) {
	RecruitingResponse = RecruitingResponse;
	TargetedSharingData = TargetedSharingData;
	search: string = '';

	mounted() {
		this.tableOptions.itemsPerPage = 10;
		this.tableOptions.sortBy = ['sortValue','recipientData.date','date'];
		this.tableOptions.sortDesc = [false, true, true];
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Name', value: 'name', sortable: false },
			{ text: 'Position', value: 'position', sortable: false },
			{ text: 'Email', value: 'email', sortable: false },
			{ text: 'Location', value: 'city', sortable: false, align: 'center' },
			{ text: 'Age', value: 'age', sortable: false, align: 'center' },
			{ text: 'Grad Year', value: 'grad', sortable: false, align: 'center' },
			{ text: 'Notes', value: 'notes', sortable: false, align: 'center' },
		];
		if( this.IsLargeScreen ) {
			headers.push({ text: 'Last Viewed', value: 'LastView', sortable: false, align: 'center' }),
			headers.push({ text: '', value: 'actions', sortable: false });
		}
		return headers;
	}
	queryRecruitingDataLoading = false;
	async loadTable() {
		this.queryRecruitingDataLoading = coachRecruitingSharingStore.queryRecruitingDataLoading;
		var query: RepositoryQuery<TargetedSharingModel> = {
			search: this.selectedAthlete?.id,
			fields: ['parentId'],
		};
		var responses: Array<RecruitingResponse> = [];
		if( this.isInView(RecruitingResponse.Yes) ) responses.push(RecruitingResponse.Yes);
		if( this.isInView(RecruitingResponse.Maybe) ) responses.push(RecruitingResponse.Maybe);
		if( this.isInView(RecruitingResponse.No) ) responses.push(RecruitingResponse.No);
		if( this.isInView(RecruitingResponse.Seen) ) responses.push(RecruitingResponse.Seen);
		if( responses.length > 0 ) query.$match = {...query.$match, ...{ 'recipientData.response': { $any: responses } } };
		if( this.isInView(RecruitingResponse.unseen) ) query.$match = {...query.$match, ...{ 'seen': false } };
		query.$match = {...query.$match, ...{ 'archiveCoach': this.showArchive } };		

		await coachRecruitingSharingStore.queryRecruitingData({ coachId: this.MyCoachId, query, options: this.TableQueryOptions } );
		this.queryRecruitingDataLoading = coachRecruitingSharingStore.queryRecruitingDataLoading;
		await this.loadAthleteInformation();
	}
	get PageLoading(): boolean {
		return this.TableLoading;
	}
	get TableLoading(): boolean {
		return coachRecruitingSharingStore.queryRecruitingDataLoading || !this.loadedAthleteInfo || this.updatingAthlete;
	}
	get TotalItems(): number {
		return coachRecruitingSharingStore.totalSharingRecords;
	}
	get ProfileShares(): Array<TargetedSharingModel> {
		return coachRecruitingSharingStore.sharingRecords;
	}
	loadedAthleteInfo = false;
	shareAthletes: Array<AthleteProfileModel> = [];
	async loadAthleteInformation() {
		this.loadedAthleteInfo = false;
		this.shareAthletes = [];
		for( const share of this.ProfileShares ) {
			try{
			const athlete: AthleteProfileModel = await athleteApi.getPublicProfile(share.parentId);
			if( this.IsNotEmpty(athlete) ) this.shareAthletes.push(athlete);
			} catch(e) {
				notificationStore.pushSnackbarWarning({message:`Error finding athlete: ${share.parentId}`})
			}
		}
		this.loadedAthleteInfo = true;
	}

	Athlete(share: TargetedSharingModel): AthleteProfileModel {
		const athlete = this.shareAthletes.find(a => a.id === share.parentId);
		if( this.IsNotEmpty(athlete) ) return athlete;
		var noAthlete = new AthleteProfileModel;
		noAthlete.email = share.email;
		noAthlete.lastName = this.SnipAthleteId(share.parentId);
		return noAthlete;
	}
	AthleteName(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.FullName;
	}
	AthletePosition(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.PositionShort;
	}
	AthleteEmail(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.Email;
	}
	AthleteCity(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.City;
	}
	AthleteAge(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.AgeGroup;
	}
	AthleteGradYear(share: TargetedSharingModel): number {
		const athlete = this.Athlete(share);
		return athlete.GradYear;
	}
	AthletePhotoUrl(share: TargetedSharingModel): string {
		const athlete = this.Athlete(share);
		return athlete.pictureUrl;
	}

	showArchive: boolean = false;
	async onToggleShowArchive() {
		this.showArchive = !this.showArchive;
		this.onResetFilters();
	}
	async onToggleArchiveCoach(share: TargetedSharingModel) {
		share.archiveCoach = !share.archiveCoach;
		await coachRecruitingSharingStore.setCoachArchive({coachId: this.MyCoachId, id: share.id, archive: share.archiveCoach});
	}
	async onViewAthlete(share: TargetedSharingModel) {
		if( !share.HasResponded ) await this.setResponse({share, response: RecruitingResponse.Seen});
		this.gotoAthlete(share.parentId);
	}
	async setResponse({ share, response } : {share: TargetedSharingModel, response: RecruitingResponse}) {
		if( share.Response === response && response != RecruitingResponse.Seen ) {
			this.setResponse({share, response: RecruitingResponse.Seen});
			return;
		}
		share.SetResponse(response);
		await coachRecruitingSharingStore.setResponse({coachId: this.MyCoachId, id: share.id, response});
	}
	async setClear(share: TargetedSharingModel) {
		await this.setResponse( {share, response: RecruitingResponse.Seen});
	}
	async setCoachNotes({ id, notes }: { id: string, notes: string }): Promise<void>{
		await coachRecruitingSharingStore.setCoachNotes( { coachId: this.MyCoachId, id, notes } );
	}

	viewResponses: Array<RecruitingResponse> = [];
	async onToggleView(response: RecruitingResponse) {
		if( this.viewResponses.includes(RecruitingResponse.unseen) ) this.viewResponses = [];

		const index = this.viewResponses.indexOf(response)
		if( index < 0 ) {
			this.viewResponses.push(response);
		} else {
			this.viewResponses.splice(index, 1);
		}
		this.updateFilter();
	}
	async onViewUnseen() {
		this.viewResponses = [RecruitingResponse.unseen];
		this.updateFilter();
	}
	async onViewAll() {
		this.onResetFilters();
	}
	async onResetFilters() {
		this.selectedAthlete = undefined;
		this.viewResponses = [];
		this.updateFilter();
	}
	isInView(response: RecruitingResponse) {
		return this.viewResponses.includes(response);
	}
	ToolTip(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].tooltip.filter.on;
		return TargetedSharingData[response].tooltip.filter.off;
	}
	ButtonColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.base;
		return TargetedSharingData[response].colors.light;
	}
	IconColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.icon;
		return TargetedSharingData[response].colors.lightIcon;
	}
	TextColor(response: RecruitingResponse) {
		if( this.isInView(response) ) return TargetedSharingData[response].colors.text;
		return TargetedSharingData[response].colors.lightText;
	}
	gotoAthlete(athleteId: string) {
		this.$router.push({
			name: CoachViewAthlete,
			params:{
				...this.$route.params,
				athleteId,
			}
		})
	}

	selectedAthlete: AthleteProfileModel;
	updatingAthlete: boolean = false;
	updateAthlete(athlete: AthleteProfileModel): void {
		this.updatingAthlete = true;
		this.selectedAthlete = athlete;
		this.updateTable();
		this.updatingAthlete = false;
	}
}
