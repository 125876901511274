<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <span>Teams</span>
      <v-spacer />
      <v-btn class="px-8" color="primary"
        :to="{ name: 'TeamCreate' }"
      >
        <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
        Add Team
      </v-btn>
    </v-card-title>

    <v-card-text class="flex-grow-1">
      <TeamList />
    </v-card-text>
  </v-card>
</template>

<script>
import TeamList from './TeamList'

export default {
	data() {
		return {
		}
	},
	name: 'TeamManagement',
	components: { TeamList }
}
</script>

<style scoped>

</style>
