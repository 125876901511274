
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { GameReportModel } from "@/models";
import { gameReportApi } from "@/api/GameReportApi";
import { VuetifyMixin, MyCoachMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin, CoachRoutingMixin } from "@/mixins";
import { PaginatedResponse, QueryOptions } from '@/../types/interfaces';
import { DataTableHeader } from 'vuetify';
import { TeamModel } from "@/models/team";
import { teamApi } from "@/api/TeamApi";

@Component({
	components: {
		Page,
	},
})
export default class CoachGameReportsSummary extends Mixins(VuetifyMixin, MyCoachMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin, CoachRoutingMixin) {
    @Prop() teamId: string;
    @Prop() action: string;

    search: string = '';
    gameReports: Array<GameReportModel> = [];
    isReportsLoading: boolean = false;
    isReportsLoaded: boolean = false;

    mounted() {
        if( this.IsNotEmpty(this.action) && this.action.trim().toLowerCase().startsWith("new") ) this.onNewReport();
        this.tableOptions.sortBy = ['date'];
        this.tableOptions.sortDesc = [true];
    }
    async loadTable() {
        this.isReportsLoading = true;
        this.isReportsLoaded = false;

        const query = this.TableQuery<GameReportModel>(this.search, ['title', 'notes']);
        query.$match = {...query.$match, ...{ coachId: this.MyCoachId }};
		const options: QueryOptions = this.TableQueryOptions;
		const response: PaginatedResponse<GameReportModel> = await gameReportApi.queryAll(query, options);
        this.gameReports = response.docs;

        await this.loadAllTeams();
        this.isReportsLoaded = true;
        this.isReportsLoading = false;
    }
	get PageLoading(): boolean {
        return !this.MyCoachIsReady || this.isReportsLoading || this.isTeamsLoading;
    }
	get TableLoading(): boolean {
		return this.isReportsLoading || this.isTeamsLoading;
	}
	get TotalItems(): number {
		return this.gameReports.length;
	}

    get TableHeaders(): Array<DataTableHeader<any>> {
        return [
            { text: 'Date', value: 'date', sortable: true, },
            { text: 'Title', value: 'title', sortable: false, },
            { text: 'Team', value: 'team', sortable: true, },
            { text: 'Location', value: 'locationId', sortable: false, },
            { text: '', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
        ] 
    }

    teams: Array<TeamModel> = [];
    isTeamsLoading: boolean = false;
    isTeamsLoaded: boolean = false;
    async loadAllTeams() {
        this.isTeamsLoading = true;
        this.isTeamsLoaded = false;
        for( const report of this.gameReports ) {
            // if no team is not assocated with this report
            if( this.IsEmpty(report.teamId) ) continue;

            const team = await this.teams.find(t => t.id == report.teamId);
            // if team already in local cache
            if( this.IsNotEmpty(team) ) continue;

            // retrieve team information from database
            const dbTeam = await teamApi.findById(report.teamId);
            if( this.IsNotEmpty(dbTeam) ) this.teams.push(dbTeam);
        }
        this.isTeamsLoaded = true;
        this.isTeamsLoading = false;
    }
    TeamName(teamId: string): string {
        const team = this.teams.find(t => t.id === teamId)
        if( this.IsEmpty(team) ) return '';
        return team.name;
    }

    async onNewReport() {
        this.gotoCoachGameReport(this.teamId);
    }
    async onOpenReport(report: GameReportModel) {
        this.gotoCoachGameReport(this.teamId, report.id);
    }
    async onDeleteReport(report: GameReportModel) {
        if( !confirm(`Delete report ${report.title}? This action cannot be undone`) ) return;
        await gameReportApi.delete(report);
        this.updateTable();
    }
}
