
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { TeamModel } from '../../../models/team';
import { CoachProfileModel } from '../../../models/coach/CoachProfileModel';
import { VuetifyMixin } from '../../../mixins/VuetifyMixin';
import { StaffRole } from '@/../types/enums';

@Component({
	components: { },
})
export default class CoachStaffPosition extends Mixins(VuetifyMixin){

	@Prop({ required: true }) team: TeamModel;
	@Prop({ required: true }) private coach: CoachProfileModel;

	get TeamInitials(){
		return this.team.name.split(' ').map(c => c.charAt(0).toLocaleUpperCase()).join('');
	}

	get StaffPosition(){
		const staff = this.team.staffMembers.find(s => s.coachId === this.coach.id);
		if(staff == undefined) return "None";
		switch(staff.role){
		case StaffRole.Admin:
			return "Administrator";
		case StaffRole.HeadCoach:
			return "Head Coach";
		case StaffRole.AssistantCoach:
			return "Assistant Coach";
		default:
			return "None";
		}
	}
}
