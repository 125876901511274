
import { Component, Prop, Mixins } from 'vue-property-decorator';
import ContextMenu from '../../ui/ContextMenu.vue';
import { ContextMenuItem } from '@/../types/interfaces';
import SoccerDepthChart from './Soccer/SoccerDepthChart.vue';
import { TeamModel, PlayerOnTeam } from '../../../models/team';
import { DepthChartPosition, DepthChartModel } from '../../../models/depthChart';
import EditPositionOverlay from '@/components/teams/DepthChart/EditPositionOverlay.vue';
import { teamDashboardStore } from '../../../store';
import { CreateDepthChartFormData } from '@/../types/interfaces';
import NewDepthChartForm from '@/components/teams/DepthChart/NewDepthChartForm.vue';
import { VuetifyMixin, FormRulesMixin, StringsMixin, BAIconsMixin } from '@/mixins';

@Component({
	components: { ContextMenu, EditPositionOverlay, SoccerDepthChart, NewDepthChartForm },
})
export default class DepthChartV2 extends Mixins(FormRulesMixin, VuetifyMixin, StringsMixin, BAIconsMixin){
	@Prop({ required: true }) team: TeamModel;

	get DepthCharts(): DepthChartModel[]{
		return this.team.depthCharts;
	}
	get DepthChartLoading(): boolean{
		return teamDashboardStore.saveDepthChartLoading || teamDashboardStore.deleteDepthChartLoading;
	}

	get SelectedTeamPlayers(): PlayerOnTeam[]{
		return this.team.players;
	}

	get DepthChartEditPositionData(): DepthChartPosition{
		return teamDashboardStore.editPositionData;
	}

	async savePosition(positionData: DepthChartPosition): Promise<void>{
		this.showEditPosition = false;
		await teamDashboardStore.saveDepthChartPosition({
			depthChartId: teamDashboardStore.editDepthChartId,
			position: positionData
		});
	}

	async cancelEdit(): Promise<void>{
		this.showEditPosition = false;
		await teamDashboardStore.cancelDepthChartPosition();
	}

	get MediumAndDown(): boolean{
		return this.$vuetify.breakpoint.mdAndDown;
	}

	get ContextMenuItems(): Array<ContextMenuItem> {
		var contextMenuItems = [{ icon: 'mdi-notebook-plus', text: "New Depth Chart", click: this.onNewDepthChart }];

		if( !this.IsDepthChartSelected ) return contextMenuItems;

		return contextMenuItems.concat([
			{ icon: 'mdi-notebook-remove-outline', text: "Delete", click: this.onDeleteDepthChart },
			{ icon: 'mdi-notebook-edit', text: "Edit", click: this.onEditDepthChart },
			{ icon: 'mdi-notebook-multiple', text: "Duplicate", click: this.onDuplicateDepthChart},
		])
	}

	get IsDepthChartSelected(): boolean {
		return this.IsNotEmpty(teamDashboardStore.selectedDepthChartId);
	}
	get SelectedDepthChartId(): string{
		return teamDashboardStore.selectedDepthChartId;
	}
	get SelectedDepthChart(): DepthChartModel{
		return teamDashboardStore.SelectedDepthChart;
	}
	async selectDepthChart(id?: string): Promise<void>{
		await teamDashboardStore.selectDepthChart(id);
	}

	get ConfirmDeleteTitle(): string{
		return this.SelectedDepthChart ? `Delete "${this.SelectedDepthChart.name}"?` : 'Delete?';
	}
	showDeleteDialog: boolean = false;
	onDeleteDepthChart(): void{
		this.showDeleteDialog = true;
	}
	async onConfirmDeleteDepthChart(): Promise<void>{
		this.showDeleteDialog = false;
		await teamDashboardStore.deleteDepthChart({
			teamId: this.team.id,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	get ConfirmDuplicateTitle(): string{
		return this.SelectedDepthChart ? `Make a copy of "${this.SelectedDepthChart.name}"?` : 'Make a copy of this depth chart?';
	}
	showDuplicateDialog: boolean = false;
	duplicateNameValid: boolean = true;
	duplicateName: string = "";
	onDuplicateDepthChart(): void{
		this.duplicateName = `Copy of ${this.SelectedDepthChart.name}`;
		this.showDuplicateDialog = true;
	}
	async duplicateSelectedDepthChart(): Promise<void>{
		this.showDuplicateDialog = false;
		await teamDashboardStore.duplicateDepthChart({
			teamId: this.team.id,
			name: this.duplicateName,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	showEditDialog: boolean = false;
	renameNameValid: boolean = true;
	newName: string = "";
	onEditDepthChart(): void{
		this.newName = this.SelectedDepthChart.name;
		this.showEditDialog = true;
	}
	get ConfirmRenameTitle(): string{
		return this.IsDepthChartSelected? `Edit ${this.SelectedDepthChart.name}` : 'Edit Depth Chart';
	}
	async onConfirmEditDepthChart(): Promise<void>{
		await teamDashboardStore.renameDepthChart({
			depthChartId: this.SelectedDepthChartId,
			teamId: this.team.id,
			newName: this.newName,
		});
		this.showEditDialog = false;
	}

	addDepthChart: boolean = false;
	onNewDepthChart(): void{
		this.addDepthChart = true;
	}

	async createDepthChart(depthChartForm: CreateDepthChartFormData): Promise<void>{
		await teamDashboardStore.createDepthChart({
			teamId: this.team.id,
			depthChartForm,
		}).catch((e) => console.error(e));
		this.addDepthChart = false;
	}

	showEditPosition: boolean = false;
	set ShowEditPosition(showEditPosition: boolean){
		if(showEditPosition === false){
			this.cancelEdit();
		}
		this.showEditPosition = showEditPosition;
	}
	get ShowEditPosition(): boolean{
		return this.showEditPosition;
	}
	editPosition(position: DepthChartPosition): void{
		teamDashboardStore.editDepthChartPosition({
			depthChartId: this.SelectedDepthChart.id,
			position,
		});
		this.showEditPosition = true;
	}
}
